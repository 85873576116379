<template>
    <!-- 移动导航栏 -->
    <div class="mobile-nav">
        <div class="mobile-nav_left">
            <img class="mobile-nav_logo" :src="$webType == 'cbia' ? mobileCbiaLogoIcon : mobileLogoIcon" />
            <span class="mobile-nav_title">{{ $webType == 'cbia' ? '中国电池数字护照网' : '中国产品数字护照网' }}</span>
        </div>
        <div class="mobile-nav_right">
            <img class="mobile-nav_user" :src="mobileUserIcon" />
            <img class="mobile-nav_download" :src="mobileDownLoadIcon" />
            <img class="mobile-nav_setting" :src="mobileSettingIcon" @click="actionDrawer(true)" />
        </div>
        <!-- 顶部弹出抽屉 -->
        <Drawer :visible.sync="drawer" :direction="direction" :show-close="false" :withHeader="false">
            <!-- header -->
            <div class="darwer-header">
                <img :src="mobileCloseIcon" @click="actionDrawer(false)" />
            </div>
            <!-- 导航栏 -->
            <div class="darwer-nav">
                <div class='darwer-nav_item' v-for="(item, index) in mobileMenus" :key="index"
                    @click="touchMenu(item, index)">
                    <div :class="['nav_item-box', index == collapseIndex ? 'active-box' : null]">
                        <span class="item-box_title">{{ item.title }}</span>
                        <img class="item-box_arrow" v-if="item.children && item.children.length"
                            :src="index == collapseIndex ? mobileArrowBottomIcon : mobileArrowRightIcon" />
                    </div>
                    <!-- 折叠面板 -->
                    <div class="nav_item-collapse" v-if="index == collapseIndex">
                        <ul class="item-collapse_list">
                            <li :class="['collapse_list-item', activeKey == subItem.path ? 'active-second' : null]"
                                v-for="(subItem, subIndex) in item.children" :key="subIndex"
                                @click="touchMenu(subItem, index)">
                                {{ subItem.title }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Drawer>
    </div>
</template>
<script>
import mobileLogoIcon from '@/assets/images/sliderNav/mobile/logo_icon.png';
import mobileCbiaLogoIcon from '@/assets/images/sliderNav/mobile/cbia_logo_icon.png';
import mobileUserIcon from '@/assets/images/sliderNav/mobile/user_icon.png';
import mobileDownLoadIcon from '@/assets/images/sliderNav/mobile/download_icon.png';
import mobileSettingIcon from '@/assets/images/sliderNav/mobile/setting_icon.png';
import mobileCloseIcon from '@/assets/images/sliderNav/mobile/drawer_close_icon.png';
import mobileArrowBottomIcon from '@/assets/images/sliderNav/mobile/arrow_bottom_icon.png';
import mobileArrowRightIcon from '@/assets/images/sliderNav/mobile/arrow_right_icon.png';
import { Drawer } from 'element-ui';
export default {
    name: 'mobileSilderNav',
    components: {
        Drawer
    },
    data() {
        return {
            collapseIndex: -1,
            activeKey: '',
            drawer: false,
            direction: 'ttb',
            mobileLogoIcon,
            mobileCbiaLogoIcon,
            mobileUserIcon,
            mobileDownLoadIcon,
            mobileSettingIcon,
            mobileCloseIcon,
            mobileArrowBottomIcon,
            mobileArrowRightIcon,
            mobileMenus: [
                {
                    title: '首页', path: '/home'
                },
                {
                    title: '关于DPP', path: '#',
                    children: [
                        { title: '关于DPP', path: '/aboutdpp' },
                        { title: '权威报告', path: '/technicalReport' },
                        { title: '技术标准', path: '/technicalStandard' }
                    ]
                },
                {
                    title: '产品数字护照', path: '#',
                    children: [
                        { title: '电池DPP', path: '/batteryIndustry' },
                        { title: '企业碳管理', path: '/carbonManage' }
                    ],
                },
                {
                    title: '新闻动态', path: '#',
                    children: [
                        { title: '政策解读', path: '/policyComment' },
                        { title: '行业资讯', path: '/industryInformation' },
                        { title: '工作动态', path: '/workNews' }
                    ],
                },
                { title: '关于我们', path: '/aboutUs' },
                { title: 'FAQ', path: '/faq' }
            ],
        }
    },
    methods: {
        // 操作导航栏
        actionDrawer(drawer) {
            this.drawer = drawer;
            if (drawer == true) this.defaultActive(this.mobileMenus);
        },
        // 点击菜单选项
        touchMenu(item, index) {
            this.collapseIndex = index;
            if (item.path != '#' && item.path != this.$route.path) {
                this.activeKey = item.path
                this.$router.push(item.path);
                this.drawer = false;
                return;
            }
            if(item.path == this.$route.path) {
                this.drawer = false;
                return;
            }
        },
        // 获取默认的激活菜单状态
        defaultActive(menus) {
            // web端激活状态
            const recursionActive = (list, defaultIndex) => {
                list.forEach((item, index) => {
                    if (this.$route.path.includes(item.path)) {
                        this.collapseIndex = defaultIndex !== undefined ? defaultIndex : index;
                        this.activeKey = item.path;
                        return;
                    }
                    if (item.children) recursionActive(item.children, index);
                })
            }
            recursionActive(menus);
        },
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>