//响应式布局 rem
export const flexible = (window, document) => {
	var docEl = document.documentElement
	var dpr = window.devicePixelRatio || 1
	// adjust body font size
	function setBodyFontSize() {
	  if (document.body && document.documentElement.clientWidth >= 750) {
		document.body.style.fontSize = (12 * dpr) + 'px'
	  }
	  else {
		document.addEventListener('DOMContentLoaded', setBodyFontSize)
	  }
	}
	setBodyFontSize();
	// 设置设计稿宽度1920px，默认字体大小100px
	function setRemUnit() {
	  var rem = docEl.clientWidth / 19.2
	  docEl.style.fontSize = rem + 'px'
	}

	setRemUnit()

	// reset rem unit on page resize
	window.addEventListener('resize', setRemUnit)
	window.addEventListener('pageshow', function (e) {
	  if (e.persisted) {
		setRemUnit()
	  }
	})

	// detect 0.5px supports
	if (dpr >= 2) {
	  var fakeBody = document.createElement('body')
	  var testElement = document.createElement('div')
	  testElement.style.border = '.5px solid transparent'
	  fakeBody.appendChild(testElement)
	  docEl.appendChild(fakeBody)
	  if (testElement.offsetHeight === 1) {
		docEl.classList.add('hairlines')
	  }
	  docEl.removeChild(fakeBody)
	}
}