<template>
    <component :is="componentTag" />
</template>
<script>
import webFooterNav from './web';
import mobileFooterNav from './mobile';
export default {
    components: {
        webFooterNav,
        mobileFooterNav
    },
    data() {
        return {
            isMobile: false,
            componentTag: ''
        }
    },
    created() {
       this.listenObserverWidth(750);
    },
    methods: {
        // 监听可视窗口宽度
        listenObserverWidth(mobileWidth) {
           const resizeObserver = new ResizeObserver(entries => {
               for(let entry of entries) {
                  if(entry.contentRect.width <= mobileWidth) {
                      this.isMobile = true;
                      this.componentTag = 'mobileFooterNav';
                  } else {
                      this.isMobile = false;
                      this.componentTag = 'webFooterNav';
                  }
               }
           });
           resizeObserver.observe(document.body);
        },
    }
}
</script>
