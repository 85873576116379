<template>
    <!-- 尾部移动导航栏 -->
    <div class="footer-nav">
        <div class="footer-nav_part1">
            <div class="nav_part1-contact">
                <div class="nav-title">联系我们</div>
                <div class="nav-list">
                    <p class="nav-list_item">
                       <img :src="locationIcon" /> 
                       <span>江苏省南京市江宁区秣周东路12号7号楼7楼</span>
                    </p>
                    <p class="nav-list_item">
                        <img :src="phoneIcon" />
                        <span>400-106-2918</span>
                    </p>
                    <p class="nav-list_item">
                        <img :src="emailIcon" />
                        <span>chdpp@fc3de.com</span>
                    </p>
                </div>
            </div>
            <div class="nav_part1-wx">
                <div class="nav-title">微信公众号</div>
                <img :src="qrcodeImg" />
            </div>
        </div>
        <div class="footer-nav_part2">
            <div class="nav-sub_title">友情链接</div>
            <div class="nav_part2-list">
                <div class="part2-list_item"><a href="https://www.miit.gov.cn/">中国人民共和国工业和信息化部</a></div>
                <div class="part2-list_item"><a href="http://www.caict.ac.cn/">中国信息通信研究院</a></div>
                <div class="part2-list_item"><a href="https://www.idfactory.cn/">中国工业互联网顶级节点</a></div>
            </div>
        </div>
    </div>
</template>
<script>
import locationIcon from '@/assets/images/footerNav/mobile/location-icon.png';
import phoneIcon from '@/assets/images/footerNav/mobile/phone-icon.png';
import emailIcon from '@/assets/images/footerNav/mobile/email-icon.png';
import qrcodeImg from '@/assets/images/footerNav/mobile/qrcode-img.png';
export default {
    name: 'mobileFooterNav',
    data() {
        return {
            locationIcon,
            phoneIcon,
            emailIcon,
            qrcodeImg
        }
    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>