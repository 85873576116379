import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "portal",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/portal/index.vue"),
    redirect: "/home",
    children: [
      {
        path: "/download",
        name: "download",
        title: "文档下载页",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/download/index.vue"),
      },
      {
        path: "/home",
        name: "home",
        title: "首页",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
      },
      {
        path: "#",
        name: "about",
        title: "关于DPP",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/layout/index.vue"),
        children: [
          {
            path: "/aboutdpp",
            name: "aboutdpp",
            title: "关于DPP",
            component: () =>
              import(
                /* webpackChunkName: "home" */ "../views/aboutdpp/index.vue"
              ),
          },
          {
            path: "/technicalReport",
            name: "technicalReport",
            title: "权威报告",
            component: () =>
              import(
                /* webpackChunkName: "home" */ "../views/technicalReport/index.vue"
              ),
          },
          {
            path: "/technicalStandard",
            name: "technicalStandard",
            title: "技术标准",
            component: () =>
              import(
                /* webpackChunkName: "home" */ "../views/technicalStandard/index.vue"
              ),
          },
        ],
      },
      {
        path: "#",
        name: "digitalPassport",
        title: "电池数字护照",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/layout/index.vue"),
        children: [
          {
            path: "/batteryIndustry",
            name: "batteryIndustry",
            title: "电池DPP",
            component: () =>
              import(
                /* webpackChunkName: "home" */ "../views/batteryIndustry/index.vue"
              ),
          },
          {
            path: "/carbonManage",
            name: "carbonManage",
            title: "企业碳管理",
            component: () =>
              import(
                /* webpackChunkName: "home" */ "../views/carbonManage/index.vue"
              ),
          },
        ],
      },
      {
        path: "#",
        name: "news",
        title: "新闻动态",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/layout/index.vue"),
        children: [
          {
            path: "/policyComment",
            name: "policyComment",
            title: "政策解读",
            component: () =>
              import(
                /* webpackChunkName: "home" */ "../views/policyComment/index.vue"
              ),
          },
          {
            path: "/industryInformation",
            name: "industryInformation",
            title: "行业资讯",
            component: () =>
              import(
                /* webpackChunkName: "home" */ "../views/industryInformation/index.vue"
              ),
          },
          {
            path: "/workNews",
            name: "workNews",
            title: "工作动态",
            component: () =>
              import(
                /* webpackChunkName: "home" */ "../views/workNews/index.vue"
              ),
          },
        ],
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        title: "关于我们",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/aboutUs/index.vue"),
      },
      {
        path: "/faq",
        name: "faq",
        title: "FAQ",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/faq/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
     if(to.hash) {
       return { 
          selector: to.hash,
          behavior: 'smooth',
          offset: { top: 100, left: 0 }
       }
     }
  }
});

router.afterEach(() => {
   window.scrollTo(0,0)
})

export default router;
