<template>
  <div class="form-nav">
    <!-- 表单弹框入口 -->
    <div class="form-nav_entry">
      <div class="nav_entry-left">
        <img :src="phoneIcon" />
        <a href="tel:4001062918">400-106-2918</a>
      </div>
      <div class="nav_entry-right">
        <Button type="primary" @click="actionDrawer(true)">申请试用</Button>
      </div>
    </div>
    <!-- 抽屉 -->
    <Drawer
      :visible.sync="drawer"
      direction="btt"
      :wrapperClosable="false"
      :withHeader="false"
      :modal="true"
      :destroy-on-close="true"
    >
      <!-- 头部 -->
      <div class="drawer-header">
        <div class="drawer-header_title">
          <span>申请试用</span>
        </div>
        <div class="drawer-header_close">
          <img :src="closeIcon" @click="actionDrawer(false)" />
        </div>
      </div>
      <!-- 表单 -->
      <div class="drawer-form">
        <Form
          ref="ruleForm"
          :model="formState"
          :rules="formRules"
          class="form_wrapper"
        >
          <FormItem label="公司名称" prop="company">
            <Input v-model="formState.company" placeholder="请输入公司名称" />
          </FormItem>
          <FormItem label="联系人" prop="name">
            <Input v-model="formState.name" placeholder="请输入姓名" />
          </FormItem>
          <FormItem label="联系电话" prop="phoneNumber">
            <Input
              v-model="formState.phoneNumber"
              placeholder="请输入联系电话"
            />
          </FormItem>
          <FormItem label="邮件地址" prop="mail">
            <Input v-model="formState.mail" placeholder="请输入邮件地址" />
          </FormItem>
          <FormItem label="部门或职位" prop="position">
            <RadioGroup v-model="formState.position">
              <Radio label="质量"></Radio>
              <Radio label="IT"></Radio>
              <Radio label="采购"></Radio>
              <Radio label="其他"></Radio>
            </RadioGroup>
          </FormItem>
        </Form>
        <div class="form_label">
          <span
            >请留下您的联系方式与申请信息，我们将安排联合试点验证专员与您联系</span
          >
        </div>
        <div class="form-btn">
          <Button type="primary" @click="submitForm('ruleForm')">提交</Button>
        </div>
      </div>
    </Drawer>
  </div>
</template>
<script>
import phoneIcon from "@/assets/images/formNav/mobile/phone.png";
import closeIcon from "@/assets/images/formNav/mobile/close.png";
import {
  Button,
  Drawer,
  Form,
  FormItem,
  Input,
  RadioGroup,
  Radio,
  Message,
} from "element-ui";
export default {
  name: "mobileFormNav",
  components: {
    Button,
    Drawer,
    Form,
    FormItem,
    Input,
    RadioGroup,
    Radio,
  },
  data() {
    return {
      phoneIcon,
      closeIcon,
      drawer: false,
      formState: {
        company: "",
        name: "",
        phoneNumber: "",
        mail: "",
        position: "",
      },
      formRules: {
        company: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value == "") {
                return callback(new Error("请输入联系电话"));
              }
              if (!/^1[3456789]\d{9}$/.test(value)) {
                return callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
          },
        ],
        mail: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value == "") {
                return callback(new Error("请输入邮件地址"));
              }
              if (
                !/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(
                  value
                )
              ) {
                return callback(new Error("请输入正确的邮箱"));
              } else {
                callback();
              }
            },
          },
        ],
        position: [
          {
            required: true,
            message: "请选择部门",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    actionDrawer(drawer) {
      this.drawer = drawer;
      // 创建遮罩层
      const actionModal = (type) => {
        // 动态添加样式属性
        const dyamicAddStyleProperty = (property) => {
          property.style.position = "fixed";
          property.style.zIndex = 999;
          property.style.top = 0;
          property.style.width = "100%";
          property.style.height = "100%";
          property.style.background = "#3333334d";
        };
        // 创建遮罩层
        const createModalDiv = () => {
          document.body.style.overflow = "hidden";
          const modal = document.createElement("div");
          modal.classList.add("modal");
          dyamicAddStyleProperty(modal);
          document.body.appendChild(modal);
          return;
        };
        // 清除遮罩层
        const removeModalDiv = () => {
          document.body.style.overflow = "auto";
          document.body.removeChild(document.querySelector(".modal"));
          return;
        };

        switch (type) {
          case "create":
            createModalDiv();
            break;
          case "remove":
            removeModalDiv();
            break;
        }
      };

      if (!drawer) {
        this.$refs["ruleForm"].resetFields();
        actionModal("remove");
      } else {
        actionModal("create");
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          requestApi({
            url: "/api/appointment/add",
            method: "POST",
            data: this.formState,
          }).then((res) => {
            if (res.code == 0) {
              Message({
                message: "已提交！稍后会有试点验证员与您联系。",
                type: "success",
              });
              this.$refs[formName].resetFields();
              this.drawer = false;
            }
          });
        } else {
          Message({
            showClose: true,
            message: "信息提交失败，请重新尝试!",
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>