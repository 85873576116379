<template>
    <component :is="componentTag" />
</template>
<script>
import mobileSilderNav from './mobile';
import webSliderNav from './web';
export default {
    components: {
        mobileSilderNav,
        webSliderNav
    },
    data() {
        return {
            isMobile: false,
            componentTag: ''
        }
    },
    created() {
        this.listenObserverWidth(750);
    },
    methods: {
        // 监听可视窗口宽度
        listenObserverWidth(mobileWidth) {
            const resizeObserver = new ResizeObserver(entries => {
                for(let entry of entries) {
                    if(entry.contentRect.width <= mobileWidth) {
                        this.isMobile = true;
                        this.componentTag = 'mobileSilderNav';
                    } else {
                        this.isMobile = false;
                        this.componentTag = 'webSliderNav'
                    }
                }
            });
            resizeObserver.observe(document.body);
        }
    }
}
</script>